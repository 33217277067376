module.exports = {
  siteTitle: 'C-Dice', // <title>
  manifestName: 'C-Dice',
  manifestShortName: 'C-Dice', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: ``, // This path is subpath of your hosting https://domain/portfolio
  // social_english
  // social
  socialLinks_en: [
    // {
    //   icon: 'fa-github',
    //   name: 'Github',
    //   url: 'https://github.com/yuri-kanagawa/cheat_dice',
    // },
    {
      icon: 'fa-youtube',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCREgeyuEfOnByFjSRMAHyaw',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/yurikanagawa_en',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/yuri.kanagawa_english/',
    },
    {
      icon: 'fa-tiktok',
      name: 'tiktok',
      url: 'https://www.tiktok.com/@yuri.kanagawa_english',
    },
  ],
  //他言語対応可能
    socialLinks_ja: [
    // {
    //   icon: 'fa-github',
    //   name: 'Github',
    //   url: 'https://github.com/yuri-kanagawa/cheat_dice',
    // },
    {
      icon: 'fa-youtube',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UC46L7rpluQY4AlcmY-VkolQ',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/yurikanagawa_jp',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/yuri.kanagawa_japanese/',
    },
    {
      icon: 'fa-tiktok',
      name: 'tiktok',
      url: 'https://www.tiktok.com/@yuri.kanagawa_japanese',
    },
  ],

  socialLinks_ru: [
    // {
    //   icon: 'fa-github',
    //   name: 'Github',
    //   url: 'https://github.com/yuri-kanagawa/cheat_dice',
    // },
    {
      icon: 'fa-youtube',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCQmq0gmEsiYFwudp-G-CQJw',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/yurikanagawa_ru',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'hhttps://www.instagram.com/yuri.kanagawa_russian/',
    },
    {
      icon: 'fa-tiktok',
      name: 'tiktok',
      url: 'https://www.tiktok.com/@yuri.kanagawa_russian',
    },
  ],

  socialLinks_fr: [
    {
      icon: 'fa-youtube',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCIg-awyjgPLLdpQEZ9eEZ_w',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/yurikanagawa_fr',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/yuri.kanagawa_french/',
    },
    {
      icon: 'fa-tiktok',
      name: 'tiktok',
      url: 'https://www.tiktok.com/@yuri.kanagawa_french',
    },
  ],

  socialLinks_de: [
    {
      icon: 'fa-youtube',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCuqcnPTGnLxFE-XLGrM-xLg',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/yurikanagawa_ge',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/yuri.kabagawa_german/',
    },
    {
      icon: 'fa-tiktok',
      name: 'tiktok',
      url: 'https://www.tiktok.com/@yuri.kanagawa_german',
    },
  ],

  socialLinks_it: [
    {
      icon: 'fa-youtube',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCdHXLb3E2d7ad_Jc0RI01ww',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/yurikanagawa_it',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/yuri.kanagawa_italian/',
    },
    {
      icon: 'fa-tiktok',
      name: 'tiktok',
      url: 'https://www.tiktok.com/@yuri.kanagawa_italian',
    },
  ],

  socialLinks_es: [
    {
      icon: 'fa-youtube',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCpNqXk66H7NblYO3XlXqkEw',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/yurikanagawa_sp',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/yuri.kanagawa_english/',//審査中
    },
    {
      icon: 'fa-tiktok',
      name: 'tiktok',
      url: 'https://www.tiktok.com/@yuri.kanagawa_spanish',
    },
  ],

  socialLinks_hi: [
    {
      icon: 'fa-youtube',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCN9nTgjtolS4HLnjjs0Qekg',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/yurikanagawa_hi',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/yuri.kanagawa_hindi/',
    },
    {
      icon: 'fa-tiktok',
      name: 'tiktok',
      url: 'https://www.tiktok.com/@yuri.kanagawa_hindi',
    },
  ],

  socialLinks_ar: [
    {
      icon: 'fa-youtube',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UC7miwJeyHCt7ef1yLmmWiMw',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/yurikanagawa_ar',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/yuri.kanagawa_arabic/',//審査中
    },
    {
      icon: 'fa-tiktok',
      name: 'tiktok',
      url: 'https://www.tiktok.com/@yuri.kanagawa_arabic',
    },
  ],

  socialLinks_pt: [
    {
      icon: 'fa-youtube',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCL-_v7Ptes5HBKLtJJWrPxQ',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/yurikangawa_po',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/yuri.kanagawa_english/',//審査中
    },
    {
      icon: 'fa-tiktok',
      name: 'tiktok',
      url: 'https://www.tiktok.com/@yuri.kanagawa_portuguese',
    },
  ],

  socialLinks_id: [
    {
      icon: 'fa-youtube',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCSEzDfa7FmoPKXgu8Us-Obw',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/yurikanagawa_id', //審査中
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/yuri.kanagawa_english/',//審査中
    },
    {
      icon: 'fa-tiktok',
      name: 'tiktok',
      url: 'https://www.tiktok.com/@yuri.kanagawa_indonesian',
    },
  ],

  socialLinks_ko: [
    {
      icon: 'fa-youtube',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCStwzdG07Hc2SDd5Vqc_4Wg',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/yurikanagawa_ko',//めんどくせ
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/yuri.kanagawa_korean/',//めんどくせ
    },
    {
      icon: 'fa-tiktok',
      name: 'tiktok',
      url: 'https://www.tiktok.com/@yuri.kanagawa_korean',
    },
  ],

  socialLinks_zh: [
    {
      icon: 'fa-youtube',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCkydybuqJXeASRJJPhDhVug',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/yurikanagawa_ch',
    },
    {
      icon: 'fa-bilibili',
      name: 'bilibili',
      url: 'https://space.bilibili.com/2006177322',
    },
    {
      icon: 'fa-weibo',
      name: 'weibo',
      url: 'https://weibo.com/u/7731584808',
    },
    {
      icon: 'fa-tiktok',
      name: 'tiktok',
      url: 'https://www.tiktok.com/@yuri.kanagawa_chinese',
    },
  ],
};